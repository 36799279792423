<script setup>
import Button from '@/Components/Button/Button.vue';
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';
const route = inject('route');
const props = defineProps({
    company: Object,
    legalFormOptions: Object,
});

const form = useForm({
    ...props.company,
});

const sendForm = () => {
    form.put(route('staff.companies.update', { company: props.company.id }), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>
<template>
    <FormKit type="form" id="FlexerUpdateForm" @submit="sendForm" :actions="false">
        <div class="grid gap-5">
            <FormKit
                type="text"
                name="commercial_name"
                :label="$t('{model} name', { model: $t('Company') })"
                v-model="form.commercial_name"
                :errors="form.errors.commercial_name"
                :value="form.commercial_name"
            />

            <FormKit
                type="select"
                select-icon="down"
                name="legal_form"
                :label="$t('Legal form')"
                v-model="form.legal_form"
                :errors="form.errors.legal_form"
                :value="form.legal_form"
                :options="legalFormOptions"
            />

            <FormKit
                type="text"
                name="kvk_number"
                :label="$t('Kvk number')"
                v-model="form.kvk_number"
                :errors="form.errors.kvk_number"
                :value="form.kvk_number"
            />
            <Button
                :processing="form.processing"
                :recentlySuccessful="form.recentlySuccessful"
                icon="/icon/done-white.svg"
                :text="$t('Save')"
            />
        </div>
    </FormKit>
</template>
